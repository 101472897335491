import React from 'react';

import { ACTION_SLUGS } from '_constants';

import { Section } from 'components/_shared/Section';
import { Markdown } from 'components/_shared/Markdown';
import { Action, ActionList } from 'components/_shared/Action';

import { useStrapiActions, useStrapiCircleResources } from 'hooks';

import './CirclePageContentPrivate.styles.scss';

export const CirclePageContentPrivate = props => {
  const { page } = props;

  const { resources } = useStrapiCircleResources();
  const { getActionBySlug } = useStrapiActions();

  const actions = getActionBySlug([
    ACTION_SLUGS.CIRCLE_MY_ACCOUNT_LINK,
    ACTION_SLUGS.CIRCLE_LOG_OUT
  ]);

  return (
    <Section
      className='circle-resources'
      heading={page.resources_title}
      variant='aside'
      headerChildren={
        <ActionList actions={actions} actionProps={{ asStyles: 'link' }} />
      }
    >
      {!!resources?.length && (
        <ul className='resources-list'>
          {resources.map(resource => (
            <li key={resource.id} className='resources-list-item'>
              <div className='resource-header'>
                {resource.title && (
                  <h4 className='resource-title'>{resource.title}</h4>
                )}
                {resource.action && (
                  <Action
                    {...resource.action}
                    size='m'
                    variant='primary'
                    asStyles='button'
                  />
                )}
              </div>
              <Markdown
                className='resource-content'
                markdown={resource.content?.data?.content}
              />
            </li>
          ))}
        </ul>
      )}
    </Section>
  );
};
