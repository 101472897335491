import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { ACTION_SLUGS } from '_constants';

import { Section } from 'components/_shared/Section';
import { Button } from 'components/_shared/Button';

import { useStrapiActions } from 'hooks';
import { selectUserBetaStatus } from 'state';

import './CirclePageIntro.styles.scss';

export const CirclePageIntro = ({ className, state = 'public', data = {} }) => {
  const { getActionBySlug } = useStrapiActions();
  const userBetaStatus = useSelector(selectUserBetaStatus);
  const isAllowedBetaStatus = userBetaStatus.match(/\bapplied|approved\b/);
  const _state = state === 'circle' && isAllowedBetaStatus ? 'beta' : state;
  const _className = cn('circle-intro', className);

  const publicActions = getActionBySlug([
    ACTION_SLUGS.CIRCLE_ENTER,
    ACTION_SLUGS.CIRCLE_LOG_IN
  ]).map(action => ({
    id: action.id,
    title: action.text,
    subtitle: action.description.data.description,
    onClick: () => action.exec()
  }));

  const { heading, supheading, subheading, actions } = [
    ['public', { actions: publicActions }],
    ['circle'],
    ['beta']
  ].reduce((_content, [key, content = {}]) => {
    return {
      ..._content,
      [key]: {
        heading: data[`${key}_intro`].title,
        supheading: data[`${key}_intro`].supheading.data.supheading,
        subheading: data[`${key}_intro`].subheading.data.subheading,
        ...content
      }
    };
  }, {})[_state];

  return (
    <Section
      variant='typography'
      className={_className}
      heading={heading}
      supheading={<span className='premium-text'>{supheading}</span>}
      subheading={subheading}
    >
      {!!actions?.length && (
        <div className='circle-intro-actions'>
          {actions.map(({ id, title, subtitle, ...action }) => (
            <Button {...action} key={id} className={cn('action', id)}>
              {title && <span className='action-title'>{title}</span>}
              {subtitle && <span className='action-subtitle'>{subtitle}</span>}
            </Button>
          ))}
        </div>
      )}
    </Section>
  );
};
