import React, { useMemo } from 'react';
import pick from 'lodash-es/pick';
import { useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';

import { Page } from 'components/_shared/Page';
import { CirclePageIntro } from './Intro';
import { CirclePageContentPrivate, CirclePageContentPublic } from './Content';
import { BetaPageCircle } from 'components/Beta';
import { SupportWidget } from '../../../../plugins/devrev-support-sdk/SupportWidget';
import { selectUserCircleStatuses, useUserAccess } from 'state';

import './CirclePage.styles.scss';

export const query = graphql`
  query CirclePageQuery {
    page: strapiCirclePage {
      seo_title
      seo_description
      seo_keywords
      public_intro {
        title
        supheading {
          data {
            supheading
          }
        }
        subheading {
          data {
            subheading
          }
        }
      }
      circle_intro {
        title
        supheading {
          data {
            supheading
          }
        }
        subheading {
          data {
            subheading
          }
        }
      }
      beta_intro {
        title
        supheading {
          data {
            supheading
          }
        }
        subheading {
          data {
            subheading
          }
        }
      }
      beta_waitlist_intro {
        title
        supheading {
          data {
            supheading
          }
        }
        subheading {
          data {
            subheading
          }
        }
      }
      articles_section {
        title
        subheading {
          data {
            subheading
          }
        }
      }
      articles {
        blog_post {
          id
        }
        plg_post {
          id
        }
      }
      video_section {
        title
        subheading {
          data {
            subheading
          }
        }
      }
      video {
        circle_video {
          id
        }
      }
      resources_title
    }
  }
`;

export const CirclePage = ({ location, variant, ...props }) => {
  const { page } = useStaticQuery(query);

  const { isAuthenticated } = useUserAccess();
  const circleStatuses = useSelector(selectUserCircleStatuses);

  const isAllowedCircleStatus =
    circleStatuses.isApplied || circleStatuses.isApproved;

  const pageConfig = useMemo(
    () => ({
      public: {
        condition: !isAuthenticated,
        contentComponent: CirclePageContentPublic
      },
      circle: {
        condition:
          variant === 'circle' || (isAuthenticated && isAllowedCircleStatus),
        contentComponent: CirclePageContentPrivate
      }
    }),
    [isAuthenticated, isAllowedCircleStatus, variant]
  );

  const [state, Content] = useMemo(() => {
    return Object.entries(pageConfig).reduce(
      (config, [state, { condition, contentComponent }]) => {
        return condition ? [state, contentComponent] : config;
      },
      ['public', CirclePageContentPublic]
    );
  }, [pageConfig]);

  if (variant === 'beta' || location.state?.isFromBeta) {
    return <BetaPageCircle {...props} page={page} />;
  }

  return (
    <Page
      className='circle'
      meta={{
        title: page.seo_title,
        description: page.seo_description,
        keywords: page.seo_keywords
      }}
      withContainer
    >
      <SupportWidget />
      <CirclePageIntro
        data={pick(page, ['public_intro', 'circle_intro', 'beta_intro'])}
        state={state}
      />
      <Content {...props} page={page} />
    </Page>
  );
};
