import React from 'react';
import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSessionToken, useUserAccess } from 'state';

export const SupportWidget = ({ props }) => {
  const token = useSelector(selectSessionToken);
  const { isAuthenticated } = useUserAccess();
  const sdkRef = useRef(null);
  useEffect(() => {
    if (isAuthenticated && !sdkRef.current) {
      // create the widget
      if (window.DevRevSupportSdk) {
        sdkRef.current = new window.DevRevSupportSdk({
          sessionToken: token
        });
      }
    }
    return () => {
      if (sdkRef.current && sdkRef.current.destory) {
        // destroy the widget
        sdkRef.current.destory();
        sdkRef.current = null;
      }
    };
  }, [isAuthenticated, token]);
  return <React.Fragment />;
};
