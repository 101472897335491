import React, { useMemo } from 'react';

import { ROUTES } from '_constants';

import { Section } from 'components/_shared/Section';
import { Divider } from 'components/_shared/Divider';
import { VideoList } from 'components/Video';
import { PostList } from 'components/Post';
import { Link } from 'components/_shared/Link';

import { useStrapiCommonContent, useStrapiPosts, useStrapiVideos } from 'hooks';

import './CirclePageContentPublic.styles.scss';

export const CirclePageContentPublic = props => {
  const { page } = props;

  const commonContent = useStrapiCommonContent();
  const { getVideosByIds } = useStrapiVideos();
  const { getPostsByIds } = useStrapiPosts();

  const videos = useMemo(() => {
    if (!page.video?.length) return [];

    const ids = page.video.map(video => {
      return (video.circle_video || video.circle_learn_video).id;
    });

    if (!ids.length) return [];

    return getVideosByIds(ids);
  }, [page.video, getVideosByIds]);

  const posts = useMemo(() => {
    if (!page.articles?.length) return [];

    const ids = page.articles.map(post => {
      return (post.blog_post || post.plg_post).id;
    });

    if (!ids.length) return [];

    return getPostsByIds(ids);
  }, [page.articles, getPostsByIds]);

  return (
    <>
      <Section
        className='circle-articles'
        variant='aside'
        heading={page.articles_section.title}
        subheading={page.articles_section.subheading.data.subheading}
        headerChildren={
          <Link href={ROUTES.BLOG} className='go-to'>
            {commonContent.go_to_blog}
          </Link>
        }
      >
        <PostList posts={posts} excerptLength={88} />

        <Divider />
      </Section>

      <Section
        className='circle-videos'
        variant='aside'
        heading={page.video_section.title}
        subheading={page.video_section.subheading.data.subheading}
        headerChildren={
          <Link href={ROUTES.DEV_REV_CIRCLE_VIDEOS} className='go-to'>
            {commonContent.go_to_videos}
          </Link>
        }
      >
        <VideoList videos={videos} descriptionLength={88} />
      </Section>
    </>
  );
};
